import { Box, spacing } from '@fortum/elemental-ui'

import type { FooterEntry } from '@/shared/contentful/types'
import { rejectNil } from '@/shared/utils/array'

import { Badges } from './Badges'
import { SeparatorRow } from './SeparatorRow'
import { SocialLinks } from './SocialLinks'

type Props = Pick<
  FooterEntry,
  'badgeImagesCollection' | 'socialLinksTitle' | 'socialLinksCollection'
>

/**
 * Second row of the footer component.
 */
export const SecondRow = (props: Props) => {
  const { socialLinksTitle } = props
  const badgeImages = props.badgeImagesCollection?.items
    ? rejectNil(props.badgeImagesCollection?.items)
    : []
  const socialLinks = props.socialLinksCollection?.items
    ? rejectNil(props.socialLinksCollection?.items)
    : []

  if (badgeImages.length === 0 && socialLinks.length === 0) {
    return null
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ default: 'column', l: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        columnGap={spacing.xs}
        rowGap={{ default: spacing.s, m: spacing.xs }}
        pv={{ default: spacing.s, l: spacing.xs }}
      >
        {badgeImages.length > 0 ? <Badges badgeImages={badgeImages} /> : <Box />}
        {socialLinks.length > 0 && (
          <SocialLinks socialLinks={socialLinks} socialLinksTitle={socialLinksTitle} />
        )}
      </Box>
      <SeparatorRow />
    </>
  )
}
